import { template as template_846f064e6ca24302ad22f5b3e18c6c24 } from "@ember/template-compiler";
const FKText = template_846f064e6ca24302ad22f5b3e18c6c24(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
