import { template as template_287df3d0b3d1433d8d8f02e089177c81 } from "@ember/template-compiler";
const FKControlMenuContainer = template_287df3d0b3d1433d8d8f02e089177c81(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
